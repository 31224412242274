import React, { useContext, useState } from "react"
import Button from "../elements/button"
import { ProgressContext } from "../context/Progress"
import Loading from "../components/loading"

const Footer = () => {
  const { nextStatus, currentStep, currentLevel } = useContext(ProgressContext)
  const [step, setStep] = currentStep
  const [next] = nextStatus
  const [level] = currentLevel

  //Loading final caluculation
  const [loading, setLoading] = useState(false)

  const handlePrev = e => {
    e.preventDefault()
    setStep(step - 1)
  }

  const handleNext = e => {
    e.preventDefault()
    let increment = 1

    //If current step is 1 and no package was selected, the form will skip ahead to step 3
    if (step === 1 && level?.noPackage) {
      increment = 2
    }
    setStep(step + increment)
  }

  const calculating = e => {
    e.preventDefault()
    setTimeout(() => {
      setStep(step + 1)
      setLoading(false)
    }, 3000)
    setLoading(true)
  }

  return (
    <footer className={`m-footer-container${step === 5 ? " -hidden" : ""}`}>
      <div className="m-footer">
        <Button
          text="Back"
          className="-secondary m-footer-back"
          disabled={step === 1}
          handleClick={handlePrev}
        />
        {step < 4 && next.visible && (
          <Button
            text="Next"
            disabled={!next.active}
            className="m-footer-next"
            handleClick={handleNext}
          />
        )}
        {step === 4 && next.visible && (
          <Button
            text="Next"
            disabled={!next.active}
            className="m-footer-next"
            handleClick={calculating}
          />
        )}
      </div>
      {loading && (
        <Loading text="Calculating your Home Care Package estimate" />
      )}
    </footer>
  )
}

export default Footer
