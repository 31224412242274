/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"
import { CloseIcon } from "../helpers/icons"
import Tooltip from "../elements/exit-tooltip"

const tooltipText = "Are you sure you want to exit the Home Care Package Calculator?"

const Nav = () => {
  const [exit, setExit] = useState(false)

  const toggleTooltip = e => {
    e.preventDefault()
    setExit(!exit)
  }

  return (
    <nav className="m-nav-container">
      <div className="m-nav">
        <a
          className="m-nav-button subtitle-two"
          href="#"
          onClick={toggleTooltip}
        >
          <CloseIcon className="m-nav-button-icon" />
          Home Care Package Calculator
        </a>
        {exit && <Tooltip text={tooltipText} toggleTooltip={toggleTooltip} />}
      </div>
    </nav>
  )
}

export default Nav
