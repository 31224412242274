import React from "react"
import PropTypes from "prop-types"
import Button from "./button"

const Tooltip = ({ text, toggleTooltip }) =>
  text && (
    <div className="e-exit-tooltip-container">
      <div className="e-exit-tooltip">
        <p className="e-exit-tooltip-text l-body-semi-bold">{text}</p>
        <Button
          text="Exit"
          className="-red"
          link="https://www.kincare.com.au"
        />
        <Button
          text="Cancel"
          className="-secondary -borderless"
          handleClick={toggleTooltip}
        />
      </div>
    </div>
  )

Tooltip.propTypes = {
  text: PropTypes.string,
  toggleTooltip: PropTypes.func,
}

export default Tooltip
