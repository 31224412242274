import React from "react"
import Lottie from "react-lottie-player"
import animation from "../data/animation.json"

const Loading = ({ text }) => (
  <div className="c-loading-container">
    <div className="c-loading-overlay">
      <div className="c-loading">
        <Lottie
          loop
          animationData={animation}
          play
          className="c-loading-animation"
        />
        {text && <p className="c-loading-text h3">{text}</p>}
      </div>
    </div>
  </div>
)

export default Loading
