/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import PropTypes from "prop-types"

const Button = ({
  text,
  link,
  className,
  disabled = false,
  handleClick = false,
}) =>
  text && (
    <React.Fragment>
      {handleClick && (
        <a
          href="#"
          disabled={disabled}
          onClick={e => handleClick(e)}
          className={`e-button${className ? ` ${className}` : ""}${
            disabled ? " -disabled" : ""
          }`}
        >
          {text}
        </a>
      )}
      {!handleClick && link && (
        <div>
          <a
            href={link}
            disabled={disabled}
            className={`e-button${className ? ` ${className}` : ""}${
              disabled ? " -disabled" : ""
            }`}
          >
            {text}
          </a>
        </div>
      )}
    </React.Fragment>
  )

export default Button

export const ButtonPropTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  className: PropTypes.string,
  handleClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.bool,
}

Button.propTypes = ButtonPropTypes
